<template>
    <div class="wrapper blog-post">
        <div class="page-header page-header-small">
            <parallax class="page-header-image" style="background-image: url('/img//pages/question-generation-header-1933.jpg')"></parallax>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title">Question Generation</h2>
                        <h4>Learn how AutoLearnify uses Question Generation to generate Practice Tests</h4>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="button-container">
                            <a href="#foo" class="btn btn-primary btn-round btn-lg">
                                <i class="now-ui-icons text_align-left"></i> Read Article
                            </a>
                            <a href="#foo" class="btn btn-icon btn-lg btn-twitter btn-round">
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a href="#foo" class="btn btn-icon btn-lg btn-facebook btn-round">
                                <i class="fab fa-facebook-square"></i>
                            </a>
                            <a href="#foo" class="btn btn-icon btn-lg btn-google btn-round">
                                <i class="fab fa-google"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>-->
            <div class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 ml-auto mr-auto">
                            
<p>
There is research and interesting empirical evidence pointing to the effectiveness of the strategy of having the readers generate the questions to the texts. However, this resource rarely appears in classrooms. In fact, during classes, at all levels of schooling, the task of understanding is organized through pre-formulated questions and leaves very little room for the readers themselves to question the text.

This article shows how AutoLearnify makes the questioning strategy available to all students to leverage the cognitive and motivational effects of question generation.
<br/>
Traditionally, questionnaires have difficulties: entering a considerable number of questions is tedious and it is possible to make typing errors. 
AutoLearnify offers advantages of automatic question creation by providing the possibility of entering any text from which specific questionnaires should be generated.

</p>                            
<!--<h3 class="title">Blaaaa</h3>-->

                            <p class="blockquote blockquote-primary">
                                “The art and science of asking questions is the source of all knowledge.”
                                <br>
                                <br>
                                <small>
                                    -Thomas Berger
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            <!--</div>-->
            <div class="section section-blog col-md-10 ml-auto mr-auto">
                <div class="container">
                    <div class="section-story-overview">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="image-container image-left" style="background-image: url('/img/pages/question-generation-types-243183.jpg')">
                                    <!-- First image on the left side -->
                                    <p class="blockquote blockquote-primary">“Change the questions you ask, the way you view the issue will be transformed and innovation will start!”
                                        <br>
                                        <br>
                                        <small>Isaac You, Question Intelligence</small>
                                    </p>
                                </div>
                                <!-- Second image on the left side of the article -->
                                <!--<div class="image-container image-left-bottom"
                                     style="background-image: url('/img/bg29.jpg')">
                                </div>-->
                            </div>
                            <div class="col-md-5">
                                <!-- First image on the right side, above the article -->
                                <div class="image-container image-right"
                                     style="background-image: url('/img/home/question-generation-webapp-opt.png')">
                                </div>
                                <h3>The Question Generation System</h3>
                                <p>In order to generate a question, the system first executes the approach of QG through pattern extraction. As with all other approaches, the input is a textual unit. If the module can generate a question, the system dismisses the other two approaches for the input at hand.
<br/>
To illustrates the process involved in QG through pattern extraction:
<br/>
<ol>
<li>After the module is loaded, all transformation pipelines are constructed through pattern extraction. This process is described in more detail below.</li>
<li>The parsed input is matched against the extracted patterns of the trans- formation pipelines. If a pattern matches, the pipeline is selected.</li>
<li>The optional function reference aux can be used to extend the pattern extracted from the textual unit and to perform further operations on the input from which a question should be generated.</li>
<li>Then the transformation instructions are applied.</li>
<li>If the pipeline entails a post-process function, it is applied.</li>
</ol>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-md-8 ml-auto mr-auto">
                            <h3 class="title">More to the Story</h3>
                            <p>A main objective of AutoLearnify is to provide the advantages of question generation from any English text to everyone. The questions obtained will be at the level of the sentence, paragraph and document. For this purpose different analyses approaches are employed:
                             <ul>
                             	<li>Questions generated based on a morphosyntactic analysis;</li>
                             	<li>Question generated based on a syntactic dependency-based analysis;</li>
                             	<li>Question generated based on lexical semantics;</li>
                             	<li>and domain specific question generation.</li>
                             </ul>
							</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section section-blog-info">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 ml-auto mr-auto">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="blog-tags">
                                        Tags:
                                        <span class="label label-primary">Test</span>
                                        <span class="label label-primary">Practice</span>
                                        <span class="label label-primary">Study Method</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                   <!-- <a href="#" class="btn btn-google btn-round pull-right">
                                        <i class="fab fa-google"></i> 232
                                    </a>
                                    <a href="#" class="btn btn-twitter btn-round pull-right">
                                        <i class="fab fa-twitter"></i> 910
                                    </a>
                                    <a href="#" class="btn btn-facebook btn-round pull-right">
                                        <i class="fab fa-facebook-square"></i> 872
                                    </a>-->
                                </div>
                            </div>
                            <hr/>
                            <!--<card type="profile" plain>
                                <template slot="raw-content">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <div class="card-avatar">
                                                <a href="#">
                                                    <img class="img img-raised" src="img/mike.jpg">
                                                </a>
                                                <div class="ripple-container"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <h4 class="card-title">Alec Thompson</h4>
                                            <p class="description">I've been trying to figure out the bed design for the
                                                master bedroom at our Hidden Hills compound...I like good music from
                                                Youtube.</p>
                                        </div>
                                        <div class="col-md-2">
                                            <button type="button" class="btn btn-default pull-right btn-round">Follow
                                            </button>
                                        </div>
                                    </div>
                                </template>
                            </card>-->
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="section section-comments">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 ml-auto mr-auto">
                            <div class="media-area">
                                <h3 class="title text-center">3 Comments</h3>
                                <comment v-for="comment in comments"
                                         :avatar="comment.avatar"
                                         :author="comment.author"
                                         :date="comment.date"
                                         :comment="comment.comment"
                                         :replies="comment.replies"
                                         :key="comment.id">
                                </comment>
                            </div>
                            <h3 class="title text-center">Post your comment</h3>
                            <div class="media media-post">
                                <a class="pull-left author" href="#">
                                    <div class="avatar">
                                        <img class="media-object img-raised" alt="64x64" src="img/olivia.jpg">
                                    </div>
                                </a>
                                <div class="media-body">
                                    <textarea class="form-control" v-model="form.comment"
                                              placeholder="Write a nice reply or go home..." rows="4"></textarea>
                                    <div class="media-footer">
                                        <a href="#" class="btn btn-primary pull-right">
                                            <i class="now-ui-icons ui-1_send"></i> Reply
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <-- end media-post
                        </div>
                    </div>
                </div>
            </div>-->
            <div class="section">
                <div class="container">
                    <div class="col-md-12">
                        <h2 class="title text-center">Similar Articles</h2>
                        <br/>
                        <div class="blogs-1" id="blogs-1">
                            <div class="row">
                                <div class="col-md-10 ml-auto mr-auto">
                                    <card type="blog" plain>
                                        <template slot="raw-content">
                                            <div class="row">
                                                <div class="col-md-5">
                                                    <div class="card-image">
                                                        <img class="img img-raised rounded"
                                                             src="/img/pages/blog-note-taking-632204.jpg" alt="">
                                                    </div>
                                                </div>
                                                <div class="col-md-7">
                                                    <h6 class="category text-info">Note Taking</h6>
                                                    <h3 class="card-title">
                                                        <a href="/uni/find-own-study-method-take-better-notes-at-university/">How To Take Better Notes at University</a>
                                                    </h3>
                                                    <p class="card-description">
                                                        Being a student is all about personal growth and developing the skills you will need later in life. To make the most of it, you have to pay attention to every little detail regarding your student life, including your notes.
                                                    </p>
                                                    <p class="author">
                                                        by
                                                        <a href="/uni/find-own-study-method-take-better-notes-at-university/">
                                                            <b>A. Valoschi</b>
                                                        </a>, August 2018
                                                    </p>
                                                </div>
                                            </div>
                                        </template>
                                    </card>
                                    <!--<card type="blog" plain>
                                        <div class="row">
                                            <div class="col-md-7">
                                                <h6 class="category text-danger">
                                                    <i class="now-ui-icons now-ui-icons media-2_sound-wave"></i> Startup
                                                </h6>
                                                <h3 class="card-title">
                                                    <a href="#">Insticator raises $5.2M to help publishers</a>
                                                </h3>
                                                <p class="card-description">
                                                    Insticator is announcing that it has raised $5.2 million in Series A
                                                    funding. The startup allows online publishers to add quizzes, polls
                                                    and other interactive elements...
                                                </p>
                                                <p class="author">
                                                    by
                                                    <a href="#">
                                                        <b>Anthony Ha</b>
                                                    </a>, 5 days ago
                                                </p>
                                            </div>
                                            <div class="col-md-5">
                                                <div class="card-image">
                                                    <img class="img img-raised rounded"
                                                         src="img/examples/card-blog6.jpg">
                                                </div>
                                            </div>
                                        </div>
                                    </card>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        Card
    } from "@/components";

    export default {
        name: "question-generation",
        components: {
            Card
        }
    }
</script>

<style>
</style>
